/* eslint-disable max-lines-per-function */
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { GlobalStatisticsService } from 'src/app/services/global-statistics.service';
import { GlobalStatistics } from 'src/app/types/global-statistics';

@Component({
  selector: 'app-global-statistics',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    CalendarModule,
    CardModule,
    FormsModule,
    ProgressSpinnerModule,
    TableModule,
  ],
  templateUrl: './global-statistics.component.html',
  styleUrl: './global-statistics.component.less',
})
export class GlobalStatisticsComponent implements OnInit, OnDestroy {
  protected statistics: WritableSignal<GlobalStatistics | null> = signal(null);
  protected loading: WritableSignal<boolean> = signal(true);
  protected dateRange: Date[] = [];

  private destroy$ = new Subject<void>();

  statisticsList: Array<{ icon: string; description: string; value: string | number }> = [];

  constructor(private globalStatisticsService: GlobalStatisticsService) {}

  ngOnInit(): void {
    this.getStatistics();
  }

  getStatistics(): void {
    const startDate = this.dateRange[0] ? this.dateRange[0].toISOString() : undefined;
    const endDate = this.dateRange[1] ? this.dateRange[1].toISOString() : undefined;

    this.globalStatisticsService
      .getStatistics(startDate, endDate)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.statistics.set(data);
          this.loading.set(false);
          const customersFilledForm = (
            (data.filledFormsFromWebsite / data.totalCustomers) *
            100
          ).toFixed(2);

          const allQuotesToClients = ((data.allFilledForms / data.totalCustomers) * 100).toFixed(2);

          this.statisticsList = [
            {
              icon: 'pi pi-file',
              description: 'Total Quotes (all quotes - even from the same customer)',
              value: data.totalFormsSubmitted,
            },
            {
              icon: 'pi pi-users',
              description: 'Total Clients (Number of all generated client numbers from website)',
              value: data.totalCustomers,
            },
            {
              icon: 'pi pi-file',
              description: 'Filled Forms (All preise forms from website, without internal)',
              value: data.filledFormsFromWebsite,
            },
            {
              icon: 'pi pi-chart-bar',
              description:
                'Percentage of Customers that filled form (from website, without internal)',
              value: `${customersFilledForm}%`,
            },
            {
              icon: 'pi pi-check',
              description: 'Total Forms (quotes added manually and submitted by customers)',
              value: data.allFilledForms,
            },
            {
              icon: 'pi pi-chart-bar',
              description:
                'Percentage of customers that entered website to quotes (added manually or submitted by customers from website)',
              value: `${allQuotesToClients}%`,
            },
            {
              icon: 'pi pi-list',
              description: 'Source HB (Basis Preisanfrageformular)',
              value: data.sourceHB,
            },
            {
              icon: 'pi pi-list',
              description: 'Source HE (Detailliertes Preisanfrageformular)',
              value: data.sourceHE,
            },
            {
              icon: 'pi pi-list',
              description: 'Source I (Internal - added manually)',
              value: data.sourceI,
            },
          ];
        },
        error: error => {
          console.error('Error fetching statistics', error);
          this.loading.set(false);
        },
      });
  }

  // Set the date range to the current month
  setThisMonth(): void {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    this.dateRange = [startOfMonth, endOfMonth];
    this.getStatistics(); // Fetch statistics for the current month
  }

  // Set the date range to the previous month
  setPreviousMonth(): void {
    const now = new Date();
    const startOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const endOfPreviousMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    this.dateRange = [startOfPreviousMonth, endOfPreviousMonth];
    this.getStatistics(); // Fetch statistics for the previous month
  }

  // Set the date range to the current quarter
  setThisQuarter(): void {
    const now = new Date();
    const quarter = Math.floor((now.getMonth() + 3) / 3);
    const startMonth = (quarter - 1) * 3;
    const startOfQuarter = new Date(now.getFullYear(), startMonth, 1);
    const endOfQuarter = new Date(now.getFullYear(), startMonth + 3, 0);
    this.dateRange = [startOfQuarter, endOfQuarter];
    this.getStatistics(); // Fetch statistics for the current quarter
  }

  // Set the date range to the previous quarter
  setPreviousQuarter(): void {
    const now = new Date();
    const quarter = Math.floor((now.getMonth() + 3) / 3) - 1;
    const startMonth = (quarter - 1) * 3;
    const startOfPreviousQuarter = new Date(now.getFullYear(), startMonth, 1);
    const endOfPreviousQuarter = new Date(now.getFullYear(), startMonth + 3, 0);
    this.dateRange = [startOfPreviousQuarter, endOfPreviousQuarter];
    this.getStatistics(); // Fetch statistics for the previous quarter
  }

  // Set the date range to the current year
  setThisYear(): void {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1); // January 1st of the current year
    const endOfYear = new Date(now.getFullYear(), 11, 31); // December 31st of the current year
    this.dateRange = [startOfYear, endOfYear];
    this.getStatistics(); // Fetch statistics for the current year
  }

  // Set the date range to the previous year
  setPreviousYear(): void {
    const now = new Date();
    const startOfPreviousYear = new Date(now.getFullYear() - 1, 0, 1); // January 1st of the previous year
    const endOfPreviousYear = new Date(now.getFullYear() - 1, 11, 31); // December 31st of the previous year
    this.dateRange = [startOfPreviousYear, endOfPreviousYear];
    this.getStatistics(); // Fetch statistics for the previous year
  }

  resetFilter(): void {
    this.dateRange = []; // Clear the date range
    this.getStatistics(); // Fetch statistics without date filters
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
