import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PdfEmailExtraInfoEditComponent } from './components/data/pdf-email-extra-info-edit/pdf-email-extra-info-edit.component';
import { PreiseEditComponent } from './components/data/preise-edit/preise-edit.component';
import { PricesEditComponent } from './components/data/prices-edit/prices-edit.component';
import { QuoteListComponent } from './components/data/quote-list/quote-list.component';
import { GlobalStatisticsComponent } from './components/global-statistics/global-statistics.component';
import { LoginComponent } from './components/login/login.component';
import { authGuard } from './guards/auth.guard';
import { loginGuard } from './guards/login.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [loginGuard] },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    component: DashboardComponent,

    children: [
      {
        path: '', // redirect to '/quotes-list when entering 'dashboard'
        redirectTo: 'quotes-list',
        pathMatch: 'full',
      },
      {
        path: 'quotes-list',
        component: QuoteListComponent,
      },
      { path: 'new-quote', component: PreiseEditComponent },
      {
        path: 'edit-quote/:id',
        component: PreiseEditComponent,
      },
      {
        path: 'prices-edit',
        component: PricesEditComponent,
      },
      {
        path: 'pdf-email-extra-info-edit',
        component: PdfEmailExtraInfoEditComponent,
      },
      {
        path: 'global-statistics',
        component: GlobalStatisticsComponent,
      },
      // customer view (quotes + stats + extras)
    ],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
