import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalStatistics } from '../types/global-statistics';
import { HttpBase } from '../utils/http-base';

@Injectable({
  providedIn: 'root',
})
export class GlobalStatisticsService {
  constructor(private http: HttpBase) {}

  getStatistics(startDate?: string, endDate?: string): Observable<GlobalStatistics> {
    let params = new HttpParams();
    if (startDate) {
      params = params.set('startDate', startDate);
    }
    if (endDate) {
      params = params.set('endDate', endDate);
    }

    return this.http.get<GlobalStatistics>('global-statistics/statistics', { params });
  }
}
