<!-- eslint-disable @angular-eslint/template/elements-content -->
<div *ngIf="loading()" class="mt-4">
  <p-progressSpinner></p-progressSpinner>
</div>
<div *ngIf="!loading() && statistics()" class="mt-4">
  <div class="mb-4">
    <div class="calendar-buttons mb-4">
      <button
        pButton
        type="button"
        label="This Month"
        class="p-button p-button-secondary p-button-sm mr-2"
        (click)="setThisMonth()"
      ></button>
      <button
        pButton
        type="button"
        label="Previous Month"
        class="p-button p-button-secondary p-button-sm mr-2"
        (click)="setPreviousMonth()"
      ></button>
      <button
        pButton
        type="button"
        label="This Quarter"
        class="p-button p-button-secondary p-button-sm mr-2"
        (click)="setThisQuarter()"
      ></button>
      <button
        pButton
        type="button"
        label="Previous Quarter"
        class="p-button p-button-secondary p-button-sm mr-2"
        (click)="setPreviousQuarter()"
      ></button>
      <button
        pButton
        type="button"
        label="This Year"
        class="p-button p-button-secondary p-button-sm mr-2"
        (click)="setThisYear()"
      ></button>
      <button
        pButton
        type="button"
        label="Previous Year"
        class="p-button p-button-secondary p-button-sm"
        (click)="setPreviousYear()"
      ></button>
    </div>

    <div class="calendar-wrapper">
      <p-calendar
        [(ngModel)]="dateRange"
        selectionMode="range"
        placeholder="Select Date Range"
        appendTo="body"
      ></p-calendar>
      <button pButton type="button" label="Filter" class="ml-2" (click)="getStatistics()"></button>
      <button
        pButton
        type="button"
        label="Reset Filter"
        class="p-button-secondary ml-2"
        (click)="resetFilter()"
      ></button>
    </div>
  </div>
  <p-card header="Global Statistics" *ngIf="statistics">
    <p-table [value]="statisticsList">
      <ng-template pTemplate="header">
        <tr>
          <th>Icon</th>
          <th>Statistic</th>
          <th>Value</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stat>
        <tr>
          <td><i [class]="stat.icon"></i></td>
          <td>{{ stat.description }}</td>
          <td>
            <span class="bold">{{ stat.value }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="mt-4 ml-3">
      <i class="pi pi-globe"></i>
      Countries:
      <ul>
        <li *ngFor="let country of statistics()?.countries">
          <span class="bold">{{ country.country }}</span> -
          <span class="bold">{{ country.count }}</span>
        </li>
      </ul>
    </div>
  </p-card>
</div>
